import {BrowserModule, provideClientHydration} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CommonModule} from "@angular/common";
import {RouterOutlet} from "@angular/router";
import {SharedModule} from "./shared/shared.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';

import {environment} from "../environments/environment";
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import {AngularFirestoreModule} from "@angular/fire/compat/firestore";
import {AngularFireStorageModule} from "@angular/fire/compat/storage";
import {LayoutModule} from "./pages/layout.module";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { AntiAdBlockComponent } from './anti-ad-block/anti-ad-block.component';

@NgModule({
  declarations: [
    AppComponent,
    CookieConsentComponent,
    AntiAdBlockComponent
  ],
  imports: [
    LayoutModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    RouterOutlet,
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
  ],
  providers: [
    provideAnalytics(() => getAnalytics()),
    ScreenTrackingService,
    UserTrackingService,
    provideAnimationsAsync(),
    provideClientHydration(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
