import * as i0 from '@angular/core';
import { InjectionToken, PLATFORM_ID, ElementRef, Component, ChangeDetectionStrategy, Inject, Input, ViewChild, NgModule } from '@angular/core';
import { isPlatformBrowser, CommonModule } from '@angular/common';
const _c0 = ["ins"];
const ADSENSE_TOKEN = new InjectionToken('AdsenseConfig');
class AdsenseComponent {
  config;
  platform;
  /** adsense account ca-pub-XXXXXXXXXXXXXXXX */
  adClient;
  /** ad slot/number */
  adSlot;
  adFormat;
  /** can be manually set if you need all ads on a page to have same id page-xxx */
  adRegion = 'page-' + Math.floor(Math.random() * 10000) + 1;
  /** ins element display style */
  display;
  /** ins element height in px */
  width;
  /** ins element width in px */
  height;
  /** used for in-feed ads */
  layout;
  /** used for in-feed ads */
  layoutKey;
  /** enable page-level ads */
  pageLevelAds;
  /** sets up some sort of google test ad */
  adtest;
  /* used for flexible ads */
  fullWidthResponsive;
  /**
   * classes applied to the ins element
   */
  className = '';
  ins;
  constructor(config, platform) {
    this.config = config;
    this.platform = platform;
  }
  ngOnInit() {
    const config = this.config;
    this.adClient = this.adClient ?? config.adClient;
    this.adSlot = this.adSlot ?? config.adSlot;
    this.adFormat = this.adFormat ?? config.adFormat ?? 'auto';
    this.display = this.display ?? config.display ?? 'block';
    this.width = this.width ?? config.width;
    this.height = this.height ?? config.height;
    this.layout = this.layout ?? config.layout;
    this.layoutKey = this.layoutKey ?? config.layoutKey;
    this.pageLevelAds = this.pageLevelAds ?? config.pageLevelAds;
    this.adtest = this.adtest ?? config.adtest;
    this.fullWidthResponsive = this.fullWidthResponsive ?? config.fullWidthResponsive;
  }
  ngOnDestroy() {
    const iframe = this.ins.nativeElement.querySelector('iframe');
    if (iframe && iframe.contentWindow) {
      iframe.src = 'about:blank';
      iframe.remove();
    }
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platform)) {
      this.push();
    }
  }
  push() {
    const p = {};
    if (this.pageLevelAds) {
      p.google_ad_client = this.adClient;
      p.enable_page_level_ads = true;
    }
    if (window) {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push(p);
      } catch {
        // pass
      }
    }
  }
  static ɵfac = function AdsenseComponent_Factory(t) {
    return new (t || AdsenseComponent)(i0.ɵɵdirectiveInject(ADSENSE_TOKEN), i0.ɵɵdirectiveInject(PLATFORM_ID));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: AdsenseComponent,
    selectors: [["ng2-adsense"], ["ng-adsense"]],
    viewQuery: function AdsenseComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 7, ElementRef);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.ins = _t.first);
      }
    },
    inputs: {
      adClient: "adClient",
      adSlot: "adSlot",
      adFormat: "adFormat",
      adRegion: "adRegion",
      display: "display",
      width: "width",
      height: "height",
      layout: "layout",
      layoutKey: "layoutKey",
      pageLevelAds: "pageLevelAds",
      adtest: "adtest",
      fullWidthResponsive: "fullWidthResponsive",
      className: "className"
    },
    decls: 2,
    vars: 17,
    consts: [["ins", ""]],
    template: function AdsenseComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelement(0, "ins", null, 0);
      }
      if (rf & 2) {
        i0.ɵɵclassMapInterpolate1("adsbygoogle ", ctx.className, "");
        i0.ɵɵstyleProp("display", ctx.display)("width", ctx.width, "px")("height", ctx.height, "px");
        i0.ɵɵattribute("data-ad-client", ctx.adClient)("data-ad-slot", ctx.adSlot)("data-ad-format", ctx.adFormat)("data-ad-region", ctx.adRegion)("data-layout", ctx.layout)("data-adtest", ctx.adtest)("data-layout-key", ctx.layoutKey)("data-full-width-responsive", ctx.fullWidthResponsive);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AdsenseComponent, [{
    type: Component,
    args: [{
      selector: 'ng2-adsense,ng-adsense',
      template: `
    <ins
      #ins
      class="adsbygoogle {{ className }}"
      [style.display]="display"
      [style.width.px]="width"
      [style.height.px]="height"
      [attr.data-ad-client]="adClient"
      [attr.data-ad-slot]="adSlot"
      [attr.data-ad-format]="adFormat"
      [attr.data-ad-region]="adRegion"
      [attr.data-layout]="layout"
      [attr.data-adtest]="adtest"
      [attr.data-layout-key]="layoutKey"
      [attr.data-full-width-responsive]="fullWidthResponsive"
    >
    </ins>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [ADSENSE_TOKEN]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], {
    adClient: [{
      type: Input
    }],
    adSlot: [{
      type: Input
    }],
    adFormat: [{
      type: Input
    }],
    adRegion: [{
      type: Input
    }],
    display: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    layout: [{
      type: Input
    }],
    layoutKey: [{
      type: Input
    }],
    pageLevelAds: [{
      type: Input
    }],
    adtest: [{
      type: Input
    }],
    fullWidthResponsive: [{
      type: Input
    }],
    className: [{
      type: Input
    }],
    ins: [{
      type: ViewChild,
      args: ['ins', {
        read: ElementRef,
        static: true
      }]
    }]
  });
})();
class AdsenseModule {
  static forRoot(config = {}) {
    return {
      ngModule: AdsenseModule,
      providers: [{
        provide: ADSENSE_TOKEN,
        useValue: config
      }]
    };
  }
  static ɵfac = function AdsenseModule_Factory(t) {
    return new (t || AdsenseModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AdsenseModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AdsenseModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [AdsenseComponent],
      declarations: [AdsenseComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ADSENSE_TOKEN, AdsenseComponent, AdsenseModule };
