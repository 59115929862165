import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../shared/services/auth.service";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss'
})
export class AuthComponent implements OnInit {
  heading = '';
  loginForm = true;
  registerForm = true;
  forgotPasswordForm = true;
  passwordResetForm = true;
  emailValidationMode = true;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.loginForm = this.router.url === '/login';
      this.registerForm = this.router.url === '/register';
      this.forgotPasswordForm = this.router.url === '/forgot-password'
      this.passwordResetForm = this.router.url.startsWith('/account-manager?mode=resetPassword');
      // verifyAndChangeEmail || verifyEmail
      this.emailValidationMode = this.router.url.startsWith('/account-manager?mode=verify');

      if(this.loginForm) {
        this.heading = 'Login';
      } else if(this.registerForm) {
        this.heading = 'Register';
      } else if(this.forgotPasswordForm) {
        this.heading = 'Forgot Password';
      } else if(this.passwordResetForm) {
        this.heading = 'Password Reset';
      } else if(this.emailValidationMode) {
        this.heading = 'Email Validation';
      } else {
        this.heading = 'Account Manager';
      }

    });
  }

  loginWithGoogle() {
    this.authService.googleLogin();
  }
}
