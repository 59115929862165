import { Component, OnInit, OnDestroy } from '@angular/core';
import { CookieConsentService } from '../shared/services/cookie-consent.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-anti-ad-block',
  templateUrl: './anti-ad-block.component.html',
  styleUrls: ['./anti-ad-block.component.scss'],
})
export class AntiAdBlockComponent implements OnInit, OnDestroy {
  private subscription!: Subscription;
  adblockDetected = false;
  flagging = false

  constructor(private cookieConsentService: CookieConsentService, ) { }

  ngOnInit(): void {
    this.cookieConsentService.loadAdsenseScript();
    this.subscription = this.cookieConsentService.scriptLoaded$.subscribe(loaded => {
      if (loaded) {
        setTimeout(() => {
          this.getElementInAllIframes(window);
        }, 5000);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getElementInAllIframes(windowRef: Window) {
    this.collectIframeValues(windowRef);
    if(!this.flagging) this.adblockDetected = true
  }

  private collectIframeValues(windowRef: Window): void {
    const iframes = windowRef.document.getElementsByTagName('iframe');
    if(!iframes.length) this.flagging = true
    for (let i = 0; i < iframes.length; i++) {
      const iframe = iframes[i];
      try {
        const iframeWindow = iframe.contentWindow;
        if (iframeWindow) {
          if(iframeWindow.document.querySelector('[id^="atContainer"]') !== null) this.flagging = true
          this.collectIframeValues(iframeWindow);
        }
      } catch (error) {
        console.error('Error accessing iframe content:', error);
      }
    }
  }
}