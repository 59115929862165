import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {UtilsService} from "./shared/services/utils.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    public utilsService: UtilsService,
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe({
      next: event => {
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
        }
      }
    });
  }
}
