import {Injectable} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})

export class AuthGuard {
  constructor(
    private router: Router,
    public auth: AngularFireAuth,
  ) {
  }

  canActivate: CanActivateFn = () => {
    return this.auth.authState.pipe(
      map(user => {
        if (user) {
          return true;
        } else {
          this.router.navigate(['login']);
          return false;
        }
      })
    );
  }
}
