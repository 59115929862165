<div class="cookie-banner" *ngIf="showBanner" data-tina-field="">
  <div class="container">
    <div class="content">
      <h2 class="title" data-tina-field="">Záleží nám na vašem soukromí</h2>
      <div class="text-block" data-tina-field="">
        <p class="description">Na našem webu používáme soubory cookie. Některé jsou nezbytné, zatímco jiné nám pomáhají zlepšovat tento web a vaši uživatelskou zkušenost.</p>
        <p class="description">Kliknutím na tlačítko „Souhlasím“ dáváte Jefrey Barber souhlas s používáním souborů cookie pro personalizaci, analýzu a cílený marketing. <a href="/use-of-cookies" title="Use of Cookies" class="link">Co jsou cookies a jak je používáme?</a></p>
      </div>
    </div>
    <div class="actions">
      <button class="btn agree" title="Souhlasím" data-tina-field="" (click)="acceptAllCookies()">Souhlasím</button>
      <button class="btn settings" title="Podrobné nastavení" data-tina-field="" (click)="showDetail()">Podrobné nastavení</button>
    </div>
  </div>

  <div *ngIf="showModal" class="cookie-modal" @modalTransition>
    <div class="header">
      <span class="modal-title">Záleží nám na vašem soukromí</span>
      <button class="close-btn" (click)="hideDetail()">
        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 384 512" class="icon">
          <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"></path>
        </svg>
      </button>
    </div>

    <div class="modal-body">
      <p class="description">Na našem webu používáme soubory cookie. Některé jsou nezbytné, zatímco jiné nám pomáhají zlepšovat tento web a vaši uživatelskou zkušenost.</p>
      <p class="description">Kliknutím na tlačítko „Souhlasím“ dáváte Jefrey Barber souhlas s používáním souborů cookie pro personalizaci, analýzu a cílený marketing. <a href="/use-of-cookies" title="Use of Cookies" class="link">Co jsou cookies a jak je používáme?</a></p>

      <div class="cookie-options">
        <div class="cookie-option">
          <label>Nezbytně nutné soubory cookie (funkční účel)</label>
          <input type="checkbox" disabled checked>
        </div>
        <div class="cookie-option">
          <label>Výkonnostní a analytické soubory cookie (analytický účel)</label>
          <input type="checkbox" (change)="checkAnalyticCheckbox($event)">
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div class="actions">
        <button class="btn agree" title="Souhlasím" (click)="acceptAllCookies()">Souhlasím</button>
        <button class="btn reject" title="Odmítnout všechny" (click)="declineCookies()">Odmítnout všechny</button>
      </div>
      <div class="settings-btn">
        <button class="btn save" title="Uložit nastavení" (click)="saveCookies()">Uložit nastavení</button>
      </div>
    </div>
  </div>
</div>