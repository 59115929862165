<div class="auth-wrapper hero-2">
  <div class="hero-bg-gradient1"></div>
  <div class="hero-bg-gradient2"></div>
  <div class="hero-gradient-ball alltuchtopdown"></div>
  <div class="ripple-shape">
    <span class="ripple-1"></span>
    <span class="ripple-2"></span>
    <span class="ripple-3"></span>
    <span class="ripple-4"></span>
    <span class="ripple-5"></span>
  </div>
  <div class="container">
    <mat-toolbar color="primary">
      <button mat-icon-button aria-label="Menu icon" (click)="toggleMenu()">
        <mat-icon>menu</mat-icon>
      </button>
      <div class="toolbar-content">
        <div class="header-profile" *ngIf="authService.appUser as appUser">
          <ul>
            <li class="menu-item-has-children">
              <a class="section-link pointer d-flex align-items-center">{{ appUser.displayName }}</a>
              <ul class="sub-menu">
                <li>
                  <a routerLink="/dashboard/user/profile" [routerLinkActive]="['active']"
                     [routerLinkActiveOptions]="{ exact: true }"><i class="fas fa-user"></i> Profile</a>
                </li>
                <li>
                  <a routerLink="/dashboard" [routerLinkActive]="['active']"
                     [routerLinkActiveOptions]="{ exact: true }"><i class="fas fa-tools"></i> Dashboard</a>
                </li>
                <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
                  <a routerLink="/dashboard/user/billing" [routerLinkActive]="['active']"
                     [routerLinkActiveOptions]="{ exact: true }"><i class="far fa-credit-card"></i> Billing</a>
                </li>
                <li>
                  <a class="pointer" (click)="authService.signOut()"><i class="fas fa-sign-out-alt"></i> Log out</a>
                </li>
              </ul>
              <div class="profile-icon">{{ appUser.displayName.charAt(0) }}</div>
            </li>
          </ul>
        </div>
      </div>
    </mat-toolbar>
    <mat-sidenav-container autosize>
      <mat-sidenav [ngClass]="!isCollapsed ? 'expanded' : ''" [mode]="isMobile ? 'over' : 'side'"
                   [opened]="isMobile ? 'false' : 'true'">
        <mat-nav-list *ngIf="!isUserSection">
          <a mat-list-item routerLink="/dashboard/tools/qr-generator" [routerLinkActive]="['active']">
            <span class="entry">
              <i class="fas fa-qrcode"></i> <span *ngIf="!isCollapsed"> QR Codes</span>
            </span>
          </a>
        </mat-nav-list>
        <mat-nav-list *ngIf="isUserSection">
          <a mat-list-item routerLink="/dashboard/user/profile" [routerLinkActive]="['active']">
            <span class="entry">
              <i class="fas fa-user"></i> <span *ngIf="!isCollapsed">Profile</span>
            </span>
          </a>
          <a mat-list-item routerLink="/dashboard/user/billing" [routerLinkActive]="['active']">
            <span class="entry">
              <i class="far fa-credit-card"></i> <span *ngIf="!isCollapsed">Billing</span>
            </span>
          </a>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <ng-container *ngIf="plainDashboard">
          <h2>Content</h2>
          <h4>Some information about project here!</h4>
        </ng-container>
        <router-outlet *ngIf="!plainDashboard"></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
