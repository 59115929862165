<div class="auth-wrapper hero-2">
  <div class="hero-bg-gradient1"></div>
  <div class="hero-bg-gradient2"></div>
  <div class="hero-gradient-ball alltuchtopdown"></div>
  <div class="ripple-shape">
    <span class="ripple-1"></span>
    <span class="ripple-2"></span>
    <span class="ripple-3"></span>
    <span class="ripple-4"></span>
    <span class="ripple-5"></span>
  </div>
  <div class="container">

    <i *ngIf="!forgotPasswordForm" class="fas fa-times close" routerLink="/"></i>
    <i *ngIf="forgotPasswordForm" class="fas fa-angle-double-left close" routerLink="/login"></i>
    <div class="document-inner-wrap">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title text-center mb-60">
            <h2 *ngIf="loginForm" class="title">Login</h2>
            <h2 *ngIf="registerForm" class="title">Register</h2>
            <h2 *ngIf="forgotPasswordForm" class="title">Forgot password</h2>
            <h2 *ngIf="passwordResetForm" class="title">Password reset</h2>
            <h2 *ngIf="emailValidationMode" class="title">Email Validation</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="document-form-wrap">
          <router-outlet></router-outlet>
          <ng-container *ngIf="!forgotPasswordForm&&!passwordResetForm&&!emailValidationMode">
            <div class="d-flex align-items-center w-100">
              <hr class="w-100">
              <span class="mx-2">OR</span>
              <hr class="w-100">
            </div>
            <div class="d-flex justify-content-center gap-4">
              <button class="btn btn3 w-100" (click)="loginWithGoogle()"><i class="fab fa-google"></i><span>Continue with Google</span></button>
            </div>
            <div class="d-flex justify-content-center mt-20">
              <p *ngIf="loginForm" class="mb-0">Don’t have an account? <a routerLink="/register">Register now!</a></p>
              <p *ngIf="registerForm" class="mb-0">Already have an account? <a routerLink="/login">Login now!</a></p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
