<div class="pop-over" *ngIf="showBanner">
  <div class="cookie-banner" data-tina-field="">
    <div class="container">
      <div class="content">
        <h2 class="title" data-tina-field="">We Care About Your Privacy</h2>
        <div class="text-block" data-tina-field="">
          <p class="description">On our website, we use cookies. Some are essential, while others help us improve the site and enhance your user experience.</p>
          <p class="description">By clicking the "I Agree" button, you consent to the use of cookies for personalization, analytics, and targeted marketing. <a href="/use-of-cookies" title="Use of Cookies" class="link">What are cookies, and how do we use them?</a></p>
        </div>
      </div>
      <div class="actions">
        <button class="btn agree" title="I Agree" data-tina-field="" (click)="acceptAllCookies()">I Agree</button>
        <button class="btn settings" title="Detailed Settings" data-tina-field="" (click)="showDetail()">Detailed Settings</button>
      </div>
    </div>
  
    <div *ngIf="showModal" class="cookie-modal" @modalTransition>
      <div class="header">
        <span class="modal-title">We Care About Your Privacy</span>
        <button class="close-btn" (click)="hideDetail()">
          <svg stroke="currentColor" fill="currentColor" viewBox="0 0 384 512" class="icon">
            <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"></path>
          </svg>
        </button>
      </div>
  
      <div class="modal-body">
        <p class="description">On our website, we use cookies. Some are essential, while others help us improve the site and enhance your user experience.</p>
        <p class="description">By clicking the "I Agree" button, you consent to the use of cookies for personalization, analytics, and targeted marketing. <a href="/use-of-cookies" title="Use of Cookies" class="link">What are cookies, and how do we use them?</a></p>
  
        <div class="cookie-options">
          <div class="cookie-option">
            <label>Essential Cookies (Functional Purpose)</label>
            <input type="checkbox" disabled checked>
          </div>
          <div class="cookie-option">
            <label>Performance and Analytics Cookies (Analytics Purpose)</label>
            <input type="checkbox" (change)="checkAnalyticCheckbox($event)">
          </div>
        </div>
      </div>
  
      <div class="modal-footer">
        <div class="actions">
          <button class="btn agree" title="I Agree" (click)="acceptAllCookies()">Accept All</button>
          <button class="btn reject" title="Decline All" (click)="acceptNecessaryCookies()">Accept Necessary</button>
        </div>
        <!-- <div class="settings-btn">
          <button class="btn save" title="Save Settings" (click)="saveCookies()">Save Settings</button>
        </div> -->
      </div>
    </div>
  </div>
</div>