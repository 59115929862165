import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router, ActivatedRoute} from "@angular/router";
import {Meta} from "@angular/platform-browser"; // Import Meta service
import {UtilsService} from "./shared/services/utils.service";
import {filter} from "rxjs/operators"; // for filtering NavigationEnd
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  adblockDetected = false;
  flagging = false

  constructor(
    private router: Router,
    private meta: Meta, // Inject the Meta service
    private activatedRoute: ActivatedRoute, // Inject ActivatedRoute service
    public utilsService: UtilsService,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  ngOnInit() {
    // Subscribe to route change events
    if (!isPlatformBrowser(this.platformId)) return
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd) // Filter for NavigationEnd event
    ).subscribe(() => {
      this.updateMetaTags();
      window.scrollTo(0, 0); // Scroll to top on route change
    });
  }

  // Method to update meta tags dynamically
  updateMetaTags() {
    const route = this.activatedRoute.root;
    this.setMetaTags(route);
  }

  // Traverse the route tree and update the meta tags based on route data
  setMetaTags(route: ActivatedRoute) {
    let child = route.firstChild;
    while (child) {
      if (child.snapshot) {
        const metaTags: any = child.snapshot;
        this.meta.updateTag({ name: 'title', content: metaTags.title })
        this.meta.updateTag({ name: 'description', content:metaTags.description || "No Description Yet" })
      }
      child = child.firstChild;
    }
  }
}