import {NgModule} from '@angular/core';
import {DefaultComponent} from "./default/default.component";
import {AuthComponent} from "./auth/auth.component";
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {SharedModule} from "../shared/shared.module";
import {NgClass, NgIf} from "@angular/common";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";

@NgModule({
  declarations: [
    DefaultComponent,
    AuthComponent,
    DashboardComponent
  ],
  imports: [
    RouterOutlet,
    SharedModule,
    NgIf,
    RouterLink,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    NgClass,
    RouterLinkActive
  ],
  providers: [],
  bootstrap: []
})
export class LayoutModule {
}
