import {Component} from '@angular/core';
import {UtilsService} from "../../shared/services/utils.service";

@Component({
  selector: 'app-default-root',
  templateUrl: './default.component.html',
  styleUrl: './default.component.scss'
})
export class DefaultComponent {
  constructor(
    public utilsService: UtilsService,
  ) {
  }
}
